import ReservationContext from 'context/ReservationContext';
import {Trans, useTranslation} from 'hooks/translations/useCustomTranslation';
import useCustomController from 'hooks/useCustomController';
import isEqual from 'lodash/isEqual';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {forwardRef, memo, useContext, useEffect, useState} from 'react';
import React from 'react';
import {useFormContext} from 'react-hook-form';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  List,
  ListSubheader,
  MenuItem,
} from '@mui/material';

import {useGetApiV1Countries} from 'lib/api/backend';

import {palette} from 'styles/mui/scss';

import {Box} from 'components/basic-components';
import {FormInputNativeDate} from 'components/basic-components/Form/FormInputNativeDate';
import {FormInputNumber} from 'components/basic-components/Form/FormInputNumber';
import {FormInputText} from 'components/basic-components/Form/FormInputText';

import styles from './step3.module.scss';

export const FormInputAddress = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      label={t('streetHouseNumber')}
      placeholder={t('streetHouseNumber')}
      sx={{width: '100%', height: '100%'}}
      name="customer.street"
      key="street"
      autoComplete="address-line1"
    />
  );
};

export const FormInputZip = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputNumber
      label={t('zip')}
      placeholder={t('zip')}
      sx={{width: '100%', height: '100%'}}
      name="customer.zip"
      key="zip"
      maxLength={5}
      autoComplete="postal-code"
      inputProps={{inputMode: 'numeric'}}
    />
  );
};

export const FormInputCity = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      label={t('city')}
      placeholder={t('city')}
      sx={{width: '100%', height: '100%'}}
      name="customer.city"
      key="city"
      autoComplete="locality"
    />
  );
};

// eslint-disable-next-line react/display-name
export const MenuItemWithRef = forwardRef<any, any>((props, ref) => (
  <MenuItem {...props} ref={ref}>
    {props.children}
  </MenuItem>
));

export const FormInputCountry = () => {
  const [openSecondary, setOpenSecondary] = useState(false);

  const {t} = useTranslation('forms');
  const {locale} = useRouter();
  const {data: countryData = [], refetch} = useGetApiV1Countries();

  useEffect(() => {
    refetch();
  }, [locale]);

  const groupedCountries = countryData.reduce((acc, country) => {
    if (!acc[country.category]) {
      acc[country.category] = [];
    }
    acc[country.category].push(country);
    return acc;
  }, {});

  const handleToggle = () => {
    setOpenSecondary(prevOpen => !prevOpen);
  };

  let countryOptions;
  let hiddenItems = [];
  const refMap = {};

  countryOptions = Object.entries(groupedCountries).flatMap(([key, countries], index) => {
    if (key === 'primary') {
      const primaryCountries = countries.map(country => {
        const Ref = React.createRef();
        refMap[country.id] = Ref;
        const hiddenRefOption = (
          <MenuItemWithRef
            ref={Ref}
            role="option"
            sx={{display: 'none'}}
            key={`hidden-country-${country.id}`}
            value={country.name}
          >
            {country.name} ({country.flag})
          </MenuItemWithRef>
        );
        hiddenItems.push(hiddenRefOption);
        return (
          <MenuItem
            onClick={() => {
              const ref = refMap[country.id]?.current;
              if (ref) {
                ref.click();
              }
            }}
            key={`${key}-${index}-${country.id}`}
            value={country.name}
          >
            {country.name} ({country.flag})
          </MenuItem>
        );
      });
      const primaryCountryList = (
        <>
          <ListSubheader className={styles.subHeader}>{t('popularCountries')}</ListSubheader>
          {primaryCountries}
        </>
      );

      return primaryCountryList;
    } else {
      const hiddenMenuItems = countries.map(country => {
        const Ref = React.createRef();
        refMap[country.id] = Ref;
        const hiddenRefOption = (
          <MenuItemWithRef
            ref={Ref}
            role="option"
            sx={{display: 'none'}}
            key={`hidden-country-${country.id}`}
            value={country.name}
          >
            {country.name} ({country.flag})
          </MenuItemWithRef>
        );
        hiddenItems.push(hiddenRefOption);
        return (
          <MenuItem
            onClick={() => {
              const ref = refMap[country.id]?.current;
              if (ref) {
                ref.click();
              }
            }}
            key={`country-visible-${country.id}`}
            value={country.name}
          >
            {country.name} ({country.flag})
          </MenuItem>
        );
      });

      const countryList = (
        <>
          <ListSubheader className={styles.subHeader}>
            <Box onClick={handleToggle} className={styles.otherCountries}>
              <span>{t('otherCountries')}</span>
              {openSecondary ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
          </ListSubheader>
          <Collapse in={openSecondary} timeout={500} unmountOnExit>
            <List component="div" disablePadding>
              {hiddenMenuItems}
            </List>
          </Collapse>
        </>
      );

      return countryList;
    }
  });

  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      select
      variant="standard"
      label={t('country')}
      placeholder={t('country')}
      name="customer.country"
      key="customer.country"
      id="country-label"
      autoComplete="country country-name"
      SelectProps={{
        onClose: () => setOpenSecondary(false),
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxHeight: 250,
              overflow: 'auto',
            },
          },
        },
      }}
    >
      {countryOptions}
      {hiddenItems}
    </FormInputText>
  );
};

export const PaymentMethodsField = () => {
  const {
    group: {paymentMethods},
    customer,
  } = useContext(ReservationContext);
  const {watch, setValue} = useFormContext();
  const paymentValue = watch('customer.payment');
  useEffect(() => {
    if (!paymentMethods.some(item => item.id === paymentValue)) {
      setValue('customer.payment', '');
    }
    if (!paymentMethods.some(item => item.id === paymentValue) && !customer?.payment?.changeable) {
      setValue('customer.payment', customer?.payment?.id);
    }
  }, [paymentMethods, paymentValue, customer?.payment?.id]);

  return <PaymentInner paymentMethods={paymentMethods} customer={customer} />;
};
// eslint-disable-next-line react/display-name
const PaymentInner = memo(function PaymentInner({paymentMethods, customer}: any) {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      select
      variant="standard"
      label={t('paymentMethod')}
      placeholder={t('paymentMethod')}
      name="customer.payment"
      key="customer.payment"
      id="customer.payment-label"
      disabled={customer?.payment && !customer?.payment?.changeable}
    >
      {customer?.payment && !customer?.payment?.changeable ? (
        <MenuItem key={customer?.payment.id} value={customer?.payment.id}>
          {customer?.payment.description}
        </MenuItem>
      ) : (
        paymentMethods.map(method => {
          return (
            <MenuItem key={method.id} value={method.id}>
              {method.description}
            </MenuItem>
          );
        })
      )}
    </FormInputText>
  );
}, isEqual);

export const BirthdaySelectField = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputNativeDate
      sx={{width: '100%', height: '100%'}}
      variant="standard"
      label={t('birthdate')}
      placeholder={t('birthdate')}
      validateOnBlur={true}
      InputLabelProps={{
        shrink: true,
      }}
      name="customer.birthday"
      key="customer.birthday"
    />
  );
};

export const PassportNumberField = () => {
  const {t} = useTranslation('forms');

  return (
    <FormInputText
      placeholder={t('idNumber')}
      label={t('idNumber')}
      name="customer.passportNumber"
      sx={{width: '100%', height: '100%'}}
    />
  );
};

export const FormInputName1 = ({company = true}) => {
  const {t} = useTranslation(['forms', 'common']);
  return (
    <FormInputText
      label={company ? t('company') : t('name', {ns: 'common'})}
      placeholder={company ? t('company') : t('name', {ns: 'common'})}
      sx={{width: '100%', height: '100%'}}
      name="customer.name1"
      key="name1"
      disabled
    />
  );
};

export const PassportDateSelectField = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputNativeDate
      sx={{width: '100%', height: '100%'}}
      variant="standard"
      label={t('idCardIssueDate')}
      validateOnBlur={true}
      InputLabelProps={{
        shrink: true,
      }}
      name="customer.passportDate"
      key="customer.passportDate"
      id="passportDate-label"
    />
  );
};

export const AlternativeGroupInput = memo(function AlternativeGroupInput() {
  const {
    additionalRequired: {alternativeGroup: options},
    group: {id},
  } = useContext(ReservationContext);
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      select
      variant="standard"
      label={t('alternativeGroup')}
      placeholder={t('alternativeGroup')}
      name="alternativeGroup"
      key="alternativeGroup"
      subText={t(`alternativeVehicleText`, {
        group: id === '05' ? '04 - MB Sprinter' : '02 - MB VITO',
      })}
    >
      {options
        .map(option => ({value: `${option}`}))
        ?.map(({value}) => {
          return (
            <MenuItem key={value} value={value}>
              {t(`alternativeGroup_${`${value}`.replace(':', '_')}`)}
            </MenuItem>
          );
        }) || null}
    </FormInputText>
  );
});
export const PersonCountInput = function PersonCountInput() {
  const {
    additionalRequired: {personCount: options},
  } = useContext(ReservationContext);
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      select
      variant="standard"
      label={t('personCount')}
      placeholder={t('personCount')}
      name="personCount"
      key="personCount"
      subText={t('carUsedByPeople', {ns: 'forms'})}
    >
      {options
        .map(option => ({value: `${option}`}))
        ?.map(({value}) => {
          return (
            <MenuItem key={value} value={value}>
              {t(`personCount_${`${value}`.replace(':', '_')}`)}
            </MenuItem>
          );
        }) || null}
    </FormInputText>
  );
};

export const TrailerLoadInput = () => {
  const {t} = useTranslation('forms');
  const {control} = useFormContext();
  const {
  } = useCustomController({control, name: 'trailerLoad'});

  return (
    <FormInputNumber
      label={t('trailerLoad')}
      placeholder={t('trailerLoad')}
      sx={{width: '100%', height: '100%'}}
      name="trailerLoad"
      key="trailerLoad"
      formatValue
      inputProps={{inputMode: 'numeric'}}
      subText={t('neededTrailerLoad', {ns: 'forms'})}
    />
  );
};
export const ContactabilityInput = () => {
  const {
    additionalRequired: {contactability: options},
  } = useContext(ReservationContext);
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      select
      variant="standard"
      label={t('availability')}
      placeholder={t('availability')}
      name="contactability"
      key="contactability"
      subText={t('callTimeText', {ns: 'forms'})}
      id="contactability-label"
    >
      {options
        .map(option => ({value: `${option}`}))
        ?.map(({value}) => {
          return (
            <MenuItem key={value} value={value}>
              {t(`contactability_${`${value}`.replace(':', '_')}`)}
            </MenuItem>
          );
        }) || null}
    </FormInputText>
  );
};

export const PublicTransportationNumberInput = () => {
  const {t} = useTranslation('forms');

  return (
    <FormInputText
      placeholder={t('publicTransportationNumber')}
      label={t('publicTransportationNumber')}
      name="publicTransportationNumber"
      sx={{width: '100%', height: '100%'}}
    />
  );
};

export const FormInputLastname = props => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      name="customer.lastName"
      inputProps={{maxLength: 29}}
      label={t('lastname')}
      placeholder={t('lastname')}
      sx={{width: '100%', height: '100%'}}
      autoComplete="family-name"
      {...props}
    />
  );
};

export const FormInputFirstname = props => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      label={t('firstname')}
      placeholder={t('firstname')}
      sx={{width: '100%', height: '100%'}}
      name="customer.firstName"
      key="firstName"
      autoComplete="given-name"
      {...props}
    />
  );
};

export const FormInputPhone = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputNumber
      label={t('phoneNumber')}
      placeholder={t('phoneNumber')}
      sx={{width: '100%', height: '100%'}}
      name="customer.phoneNumber"
      key="phoneNumber"
      autoComplete="tel-national"
      regex={/^[0-9]+$/}
      validateOnBlur={true}
      inputProps={{inputMode: 'numeric'}}
    />
  );
};

export const FormInputEmail = props => {
  const {t} = useTranslation(['forms', 'common']);
  return (
    <FormInputText
      label={t('email', {ns: 'common'})}
      placeholder={t('email', {ns: 'common'})}
      sx={{width: '100%', height: '100%'}}
      name="customer.email"
      key="email"
      autoComplete="email"
      validateOnBlur={true}
      {...props}
    />
  );
};

export const FormInputMessage = ({rows = 7}) => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      multiline
      rows={rows}
      label={t('yourMessage')}
      name="message"
      key="message"
      placeholder={t('yourMessage')}
    />
  );
};

export const FormInputCompany = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      label={t('company')}
      placeholder={t('company')}
      name="customer.company"
      key="customer.company"
      autoComplete="organization"
    />
  );
};

export const FormInputSalutation = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      select
      variant="standard"
      label={t('salutation')}
      placeholder={t('salutation')}
      name="customer.salutation"
      key="customer.salutation"
      id="salutation-label"
    >
      <MenuItem value={'contact.salutation.mr'}>{t('contact.salutation.mr')}</MenuItem>
      <MenuItem value={'contact.salutation.mrs'}>{t('contact.salutation.mrs')}</MenuItem>
      <MenuItem value={'contact.salutation.divers'}>{t('contact.salutation.divers')}</MenuItem>
      <MenuItem value={'contact.salutation.mr_dr'}>{t('contact.salutation.mr_dr')}</MenuItem>
      <MenuItem value={'contact.salutation.mrs_dr'}>{t('contact.salutation.mrs_dr')}</MenuItem>
    </FormInputText>
  );
};

export const FormInputTerms = () => {
  const {t} = useTranslation('forms');
  // const {
  //   control,
  //   formState: {errors},
  // } = useFormContext();
  const {
    control,
    formState: {errors},
  } = useFormContext();
  const {
    field: {onChange, value, onBlur, name, ref},
  } = useCustomController({control, name: 'acceptTerms'});
  return (
    <FormControl>
      <FormControlLabel
        sx={{
          alignItems: 'flex-start',
          margin: 0,
          '& .MuiFormControlLabel-label': {lineHeight: '24px'},
        }}
        control={
          <Checkbox
            sx={{padding: 0, marginRight: '10px', '&.Mui-checked': {color: palette.colorBlack}}}
            ref={ref}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            required
          />
        }
        label={
          <strong>
            <Trans
              t={t}
              i18nKey="acceptDataPrivacyAGB"
              components={[
                <Link style={{color: 'inherit'}} key="AGB" href="/agb" target="_blank" />,
                <Link
                  style={{color: 'inherit'}}
                  key="privacyPolicy"
                  href="/datenschutz"
                  target="_blank"
                />,
              ]}
            />
          </strong>
        }
      />
      <FormHelperText error>
        <>{errors.acceptTerms?.message as any}</>
      </FormHelperText>
    </FormControl>
  );
};

export const FormInputNewsletter = () => {
  const {t} = useTranslation('forms');
  const {control} = useFormContext();
  const {
    field: {onChange, value, onBlur, name, ref},
  } = useCustomController({control, name: 'subscribeNewsletter'});
  return (
    <FormControlLabel
      sx={{
        alignItems: 'flex-start',
        margin: 0,
        '& .MuiFormControlLabel-label': {lineHeight: '24px'},
      }}
      control={
        <Checkbox
          sx={{padding: 0, marginRight: '10px', '&.Mui-checked': {color: palette.colorBlack}}}
          ref={ref}
          onChange={onChange}
          checked={value}
          onBlur={onBlur}
          name={name}
        />
      }
      label={t('newsletterHint')}
    />
  );
};

export const FormInputMarketing = () => {
  const {t} = useTranslation('forms');
  const {control} = useFormContext();
  const {
    field: {onChange, value, onBlur, name, ref},
  } = useCustomController({control, name: 'acceptMarketing'});

  return (
    <FormControlLabel
      sx={{
        alignItems: 'flex-start',
        margin: 0,
        '& .MuiFormControlLabel-label': {lineHeight: '24px'},
      }}
      control={
        <Checkbox
          sx={{padding: 0, marginRight: '10px', '&.Mui-checked': {color: palette.colorBlack}}}
          ref={ref}
          onChange={onChange}
          checked={value}
          onBlur={onBlur}
          name={name}
        />
      }
      label={t('dataStoredForMarketingHint')}
    />
  );
};

export const FormInputRegister = () => {
  const {t} = useTranslation('forms');
  const {control} = useFormContext();
  const {
    field: {onChange, value, onBlur, name, ref},
  } = useCustomController({control, name: 'createLogin'});

  return (
    <FormControlLabel
      sx={{
        alignItems: 'flex-start',
        margin: 0,
        '& .MuiFormControlLabel-label': {lineHeight: '24px'},
      }}
      control={
        <Checkbox
          sx={{padding: 0, marginRight: '10px', '&.Mui-checked': {color: palette.colorBlack}}}
          ref={ref}
          onChange={onChange}
          checked={value}
          onBlur={onBlur}
          name={name}
        />
      }
      label={t('registerNowInstantDiscount')}
    />
  );
};
